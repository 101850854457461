import { navigate } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Close from '../../assets/icons/close.svg';
import CloseEN from '../../assets/icons/close-en.svg';
import Webform from '../Webform/Webform';

const WebformModal = ({ node, closeTo, language}) => {
  const { t } = useTranslation();

  return (
    <div className="contact-modal">
      <button
        title={t("contact_form.close")}
        className="modal-close-button"
        onClick={() => {
          navigate(closeTo)
        }}
        >
        {language.toLowerCase() === 'nl' ? <Close /> : <CloseEN />}
      </button>
      <Webform node={node} id="contact" onClose={() => navigate(closeTo)} />
    </div>
  )
}

WebformModal.propsTypes = {
  node: PropTypes.string,
  closeTo: PropTypes.string,
  language: PropTypes.string,
}

export default WebformModal;
