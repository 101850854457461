import React, { useState } from "react"
import PropTypes from "prop-types"
import Select from 'react-select'

function SelectWidget(props) {
  const emptyOption = props.options.empty
  const options = props.options.enumOptions
  const [selected, setSelected] = useState([])
  const width = `${props.options.size}%`

  const onChange = (option) => {
    setSelected(option) // Set value of react-select widget (label and value)
    props.onChange(option.value) // Set value of custom widget in formData
  }

  return (
    <div className="wrapper" style={{width: width}}>
      <Select
        placeholder={emptyOption}
        className={`react-select ${props.value !== undefined && props.value !== '' ? ' has-value' : ''}`}
        options={options}
        value={selected}
        onChange={onChange}
        components={{IndicatorSeparator: () => null}}
      />
    </div>
  )
}

if (process.env.NODE_ENV !== "production") {
  SelectWidget.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    id: PropTypes.string,
  };
}

export default SelectWidget
