import React, { useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from 'react-i18next'

function FileUploadWidget(props) {
  const [fileInfo, setFileInfo] = useState([])
  const { t } = useTranslation()
  let fileRef = React.createRef()
  const width = `${props.options.size}%`
  let accept = ''
  if (props.options.accept !== undefined) {
    accept = props.options.accept
  }

  const getTotalFilesLabel = () => {
    if (allowMultipleFiles()) {
      if (props.options.multiple === true) {
        return t('contact_form.unlimited_files', {files: props.options.multiple})
      }
      return t('contact_form.multiple_files', {files: props.options.multiple})
    }

    return t('contact_form.one_file')
  }

  const onChange = async (event) => {
    if (event.target.files.length > 0) {
      if (allowMultipleFiles() && props.options.multiple !== true && event.target.files.length > props.options.multiple) {
        props.onChange([`ERROR: ${t('contact_form.too_many_files', {files: props.options.multiple})}.`])
        return
      }

      let isValid = true
      let fileInfo = []
      const allowedTypes = accept.replace(/ /g,'').split(',')
      const allowedJpeg = ['.jpeg', '.jpg', '.jpe', '.jfif' ]

      Array.from(event.target.files)
        .filter(file => file !== undefined)
        .forEach((file, index) => {
          const extension = file.name.split('.').pop()

          if ( !(allowedTypes.includes(`.${extension}`) || (allowedTypes.includes('.jpeg') && allowedJpeg.includes(`.${extension}`)))) {
            // Set error.
            isValid = false
            props.onChange([`ERROR: ${t('contact_form.not_allowed_type')}.`])
          }
          else {
            fileInfo.push({
              key: index,
              name: file.name,
              size: `${(file.size * 0.0009765625).toFixed(2)} KB`,
            })
            const fileSizeMB = 0.00000095367432 * file.size

            if (props.options.maxfilesize !== undefined && fileSizeMB > props.options.maxfilesize) {
              // Set error.
              isValid = false
              props.onChange([`ERROR: ${t('contact_form.not_allowed_type', {
                filesize: fileSizeMB.toFixed(2),
                maxfilesize: props.options.maxfilesize})
              }.`])
            }
          }
        })

        if (isValid) {
          setFileInfo(fileInfo)

          return Promise.all(processFiles(event.target.files)).then((files) => {
            const dataUrls = files.map(file => file.dataUrl)
            props.onChange(dataUrls)
          });
        }
    }
    else {
      setFileInfo([])
      props.onChange('')
    }
  }

  const processFiles = (files) => {
    return [].map.call(files, processFile)
  }

  const processFile = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        const dataUrl = fileReader.result.replace(";base64", ";name=".concat(encodeURIComponent(file.name), ";base64"));

        resolve({
          dataUrl: dataUrl,
          name: file.name,
          size: file.size,
          type: file.type,
        })
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }

  const deleteFile = (event) => {
    event.preventDefault()

    fileRef.current.value = ''
    setFileInfo([])
  }

  const allowMultipleFiles = () => {
    return props.options.multiple === true || props.options.multiple > 1
  }

  return (
    <div>
      <input
        className={`file-upload ${props.value !== undefined && props.value !== '' ? ' has-value' : ''}`}
        id={props.id}
        type="file"
        accept={accept.replace('.jpeg', '.jpeg, .jpg, .jpe, .jfif')}
        onChange={(e) => onChange(e)}
        ref={fileRef}
        style={{width: width}}
        multiple={allowMultipleFiles()}
        aria-required={props.required ?? 'false'}
        aria-invalid={props.rawErrors && props.rawErrors.length ? 'true' : undefined}
        aria-describedby={props.rawErrors && props.rawErrors.length ? `${props.id}-error` : undefined}
      />
      {fileInfo.length > 0 && fileInfo.map(info => {
        return (
          <div key={info.key}>
            <span className="file-info">{info.name} <em>({info.size})</em></span>
            {fileInfo.length == 1 && (
              <button className="button tertiary" onClick={(e) => deleteFile(e)}>{t('contact_form.delete')}</button>
            )}
          </div>
        )
      })}
      {fileInfo.length > 1 && (
        <div>
          <button className="button tertiary" onClick={(e) => deleteFile(e)}>{t('contact_form.delete_multiple')}</button>
        </div>
      )}
      <div className="description">
        {getTotalFilesLabel()}.
        {props.options.maxfilesize !== undefined ? <><br />{t('contact_form.limit', {maxfilesize: props.options.maxfilesize})}.</> : ''}
        {accept ? <><br />{t('contact_form.allowed_types', {extensions: accept.replaceAll('.', '')})}.</> : ''}
        </div>
    </div>

  )
}

if (process.env.NODE_ENV !== "production") {
  FileUploadWidget.propTypes = {
    value: PropTypes.array,
    id: PropTypes.string,
  };
}

export default FileUploadWidget;
