import React, { useState } from "react"
import PropTypes from "prop-types"
import Select from 'react-select'
import { useTranslation } from 'react-i18next'

function SelectOtherWidget(props) {
  const { t } = useTranslation()
  const emptyOption = props.options.empty
  const options = props.options.listoptions
  const [selected, setSelected] = useState([])
  const [showOther, setShowOther] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const width = `${props.options.size}%`

  const onChange = (option) => {
    setShowOther(option.value === 'other')
    setSelected(option) // Set value of react-select widget (label and value)
    if (option.value !== 'other') {
      props.onChange(option.value) // Set value of custom widget in formData
    }
  }

  const onInputChange = (e) => {
    setInputValue(e.target.value)
    props.onChange(e.target.value)
  }

  return (
    <div className="wrapper" style={{width: width}}>
      <Select
        placeholder={emptyOption}
        className={`react-select ${props.value !== undefined && props.value !== '' ? ' has-value' : ''}`}
        options={options}
        value={selected}
        onChange={onChange}
        components={{IndicatorSeparator: () => null}}
      />
      {showOther &&
        <input
          className={`form-control select-other ${props.value !== undefined && props.value === 'other' ? ' has-value' : ''}`}
          placeholder={t('contact_form.other_placeholder')}
          type="text"
          onChange={onInputChange}
          value={inputValue}
          >
        </input>
      }
    </div>
  )
}

if (process.env.NODE_ENV !== "production") {
  SelectOtherWidget.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    id: PropTypes.string,
  };
}

export default SelectOtherWidget
