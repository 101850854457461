import React from 'react'
import Form from 'react-jsonschema-form'
import axios from 'axios'
import Button from '../Button/Button'
import localize from 'ajv-i18n'
import _ from 'lodash'
import RichText from '../RichText/RichText'
import Right from '../../assets/icons/chevron_right.svg'
import { Trans, withTranslation } from 'react-i18next'
import FieldTemplate from './FieldTemplate'
import InputWidget from './InputWidget'
import SelectWidget from './SelectWidget'
import SelectOtherWidget from './SelectOtherWidget'
import TextAreaWidget from './TextAreaWidget'
import MarkupWidget from './MarkupWidget'
import FileUploadWidget from './FileUploadWidget'
import Logo from '../../assets/logo.svg'
import { v4 as uuid } from 'uuid';

class Webform extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      schema: null,
      ui: null,
      buttons: null,
      liveValidate: false,
      formData: {},
      disabled: false,
      submitted: false,
      rejected: false,
      userMessages: []
    }
    this.handleClick = this.handleClick.bind(this)
    this.handleEscape = this.handleEscape.bind(this)
  }

  handleClick(e) {
    const contentRef = document.querySelector('.ReactModal__Content.roadmap')
    if (!contentRef.contains(e.target)) {
      this.props.onClose()
    }
  }

  handleEscape(e) {
    if (e.key === 'Escape' || e.key === 'Esc') {
      this.props.onClose()
    }
  }

  componentDidMount() {
    axios.get(this.getEndoint())
      .then(response => {
        document.addEventListener('keydown', this.handleEscape, false)
        document.addEventListener('mousedown', this.handleClick, false)

        if (response.status === 200) {
          const { schema, ui, buttons } = response.data
          this.setState({ schema, ui: this.enhanceUiSchema(schema, ui), buttons })
        }
      })
      .catch((error) => {
        this.setState({ endpointError: true });
      })
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick)
    document.removeEventListener('keydown', this.handleEscape)
  }

  enhanceUiSchema(schema, uiSchema) {
    Object.keys(schema.properties).forEach(key => {

      if (typeof uiSchema[key] === 'undefined') {
        uiSchema[key] = {}
      }
    })

    return uiSchema
  }

  getEndoint() {
    const formTargetId = this.props.node?.webform?.targetId || 'contact'

    if (this.props.language && this.props.language.toLowerCase() === 'en') {
      return `/en/webform_jsonschema/${formTargetId}`
    } else {
      return `/webform_jsonschema/${formTargetId}`
    }
  }

  transformErrors(errors) {
    const t = this.props.t
    const uiSchema = this.state.ui

    return errors.map(error => {
      if (error.name === "required") {
        let errorMessage = t('contact_form.required_fields_notice')
        const missingProperty = _.get(error, 'params.missingProperty')

        if (missingProperty && uiSchema[missingProperty]) {
          const requiredErrorMessage = _.get(uiSchema[missingProperty], 'webform:validationErrorMessages.required')

          if (requiredErrorMessage) {
            errorMessage = requiredErrorMessage
          }
        }

        error.message = errorMessage
      }
      else if (error.name === "format" && error.params.format === "email") {
        error.message = t('contact_form.required_valid_email_notice')
      }
      return error
    })
  }

  validate(formData, errors) {
    let uiSchema = this.state.ui

    // Find 'file' widget(s).
    let nonWidgetKeys = ['ui:order', 'webform:generalSubmissionErrorMessage']
    for (const [key, value] of Object.entries(uiSchema)) {
      if (!nonWidgetKeys.includes(key)) {
        if (value['ui:widget'] === 'file') {
          // Check value in formData.
          if (formData[key] && Array.isArray(formData[key])) {
            formData[key].forEach(data => {
              if (data !== undefined && data.startsWith('ERROR:')) {
                errors[key].addError(data.replace('ERROR:', ''))
              }
            })
          }
        }
      }
    }

    return errors
  }

  render() {
    const { submitted, ui, endpointError } = this.state;
    if ((ui && ui.is_closed) || endpointError) {
      return this.renderWebFormClosed()
    }
    if (submitted) {
      return this.renderCloseScreen()
    } else {
      return this.renderForm()
    }
  }

  renderForm() {
    const { node } = this.props
    if (node) {
      return (
        <div className="contact-form">
          <div className="title-wrapper">
            <Logo />
            <h2 className="title" id="heading">{node.title}</h2>
          </div>

          <RichText body={_.get(node, 'body.processed')} />
          {this.state.schema && ([
            <div key="explanation" className="required-explanation">
              <small>* <Trans i18nKey="contact_form.required_fields">verplichte velden</Trans></small>
            </div>,
            <Form
              key="form"
              schema={this.state.schema}
              uiSchema={this.state.ui}
              onChange={({ formData }) => {
                this.setState({ formData })
              }}
              onSubmit={async ({ formData }) => {
                const self = this
                self.setState({ disabled: true, formData })
                await axios.post(self.getEndoint(), formData)
                .then(function (response) {
                  if (response.status === 200) {
                    self.setState({ submitted: true, userMessages: response.data.userMessages })
                  }
                })
                .catch(function (error) {
                  const errorMsg = [];
                  error.response.data.errors.map(msg => errorMsg.push(msg.userMessage));
                  self.setState({ submitted: true, rejected: true, userMessages: errorMsg });
                 })
              }}
              onError={(errors) => {
                localize.nl(errors)
                this.setState({ liveValidate: true })
              }}
              widgets={{
                "input": InputWidget,
                "react-select": SelectWidget,
                "react-select-other": SelectOtherWidget,
                "textarea": TextAreaWidget,
                "markup": MarkupWidget,
                "file": FileUploadWidget,
              }}
              formData={this.state.formData}
              liveValidate={this.state.liveValidate}
              noHtml5Validate
              showErrorList={false}
              className="contact-form"
              transformErrors={this.transformErrors.bind(this)}
              FieldTemplate={FieldTemplate}
              validate={this.validate.bind(this)}
            >
              <Button className="primary" disabled={this.state.disabled} type="submit">
                <Trans i18nKey="contact_form.send">Verzenden</Trans>
              </Button>
            </Form>
          ])}
        </div>
      )
    }
  }

  renderCloseScreen() {
    return (
      <div className="contact-form">
        <h1 className="title">
          {this.state.rejected ? (
            <Trans i18nKey="contact_form.rejected">Niet verzonden</Trans>
          ) : (
            <Trans i18nKey="contact_form.submitted">Verzonden</Trans>
          )}
        </h1>
        {this.state.userMessages.map(message => <RichText key={uuid()} body={message.replace("\n", "<br />")} />)}

        <div>
          <button type="button" className="button primary" onClick={() => this.props.onClose()}>
            <span><Trans i18nKey="contact_form.close">Sluiten</Trans></span>
            <Right />
          </button>
        </div>
      </div>
    )
  }
  renderWebFormClosed() {

    return (
      <div className="contact-form">
        <h1 className="title">
          {this.state.ui ? (
            <span>{this.state.ui.closed_message}</span>
          ) : (
            <Trans i18nKey="contact_form.closed">The webform is currently closed, please try again later.</Trans>
          )}
        </h1>
      </div>
    )
  }
}

export default withTranslation()(Webform)
