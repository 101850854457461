import React from "react";
import PropTypes from "prop-types"

function InputWidget(props) {
  const { BaseInput } = props.registry.widgets;
  const width = `${props.options.size}%`
  
  return (
    <BaseInput
      {...props}
      style={{width: width}}

      autoComplete={props.options.autocomplete || undefined}
      className={`form-control ${props.value !== undefined && props.value !== '' ? ' has-value' : ''}`}
      aria-required={props.required}
      aria-invalid={props.rawErrors && props.rawErrors.length ? 'true' : undefined}
      aria-describedby={props.rawErrors && props.rawErrors.length ? `${props.id}-error` : undefined}
    />
  )
}

if (process.env.NODE_ENV !== "production") {
  InputWidget.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    id: PropTypes.string,
  };
}

export default InputWidget;
