import React from "react"
import PropTypes from "prop-types"

function MarkupWidget(props) {
  const width = `${props.options.size}%`
  const markup = props.schema.markup !== undefined ? props.schema.markup : ''
  return (
    <div className="wrapper" style={{width: width}} dangerouslySetInnerHTML={{__html:markup}} />
  )
}

if (process.env.NODE_ENV !== "production") {
  MarkupWidget.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    id: PropTypes.string,
  };
}

export default MarkupWidget
