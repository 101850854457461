import { graphql, navigate } from 'gatsby';
import React from "react"
import Modal from 'react-modal';
import Layout from "../components/Layout/Layout"
import ContentContainer from '../components/ContentContainer/ContentContainer';
import FlexBox from '../components/FlexBox/FlexBox'
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing-3'
import _ from 'lodash';
import MenuContext from '../util/MenuContext';
import FocusTrap from 'focus-trap-react';
import BaseHead from '../components/BaseHead/BaseHead';
import WebformModal from '../components/WebformModal/WebformModal';

const webform = ({data, pageContext: { language, languageUrls } }) => {
  const node = data.cms.node;

  const menus = {
    top: _.get(data, 'topMenu.menuByName.links', []),
    footer: _.get(data, 'footerMenu.menuByName.links', []),
    popular: _.get(data, 'popularMenu.menuByName.links', [])
  }
  return (
    <ModalRoutingContext.Consumer>
      {({ modal, closeTo }) => (
        <div>
          { modal ? (
            <FocusTrap
              active={true}
              focusTrapOptions={{
                escapeDeactivates: false,
                initialFocus: ".ReactModal__Content",
                fallbackFocus: ".ReactModal__Content"
              }}
            >
              <WebformModal node={node} language={language} closeTo={closeTo}/>
            </FocusTrap>
          ) : (
            <MenuContext.Provider value={menus}>
              <Layout language={language} urls={languageUrls}>
              <main id="content">
                <ContentContainer width="small">
                  <FlexBox>

                </FlexBox>
                </ContentContainer>
                <Modal
                    isOpen
                    aria={{
                      labelledby: 'heading',
                      describedby: 'full_description',
                      modal: 'true',
                    }}
                    className="roadmap"
                    shouldCloseOnOverlayClick
                    onRequestClose={null}
                    ariaHideApp={false}
                    style={{
                      overlay: {
                        backgroundColor: 'rgba(40, 40, 40, 0.75)',
                        zIndex: '101',
                      },
                      content: {
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        right: '0',
                        bottom: '0',
                        border: 'none',
                        borderRadius: '0',
                        padding: '0',
                      },
                    }}
                  >
                    <WebformModal node={node} language={language} closeTo="/"/>
                </Modal>
              </main>
            </Layout>
          </MenuContext.Provider>
        )}
        </div>
      )}
    </ModalRoutingContext.Consumer>
  )
}

const WebformHead = ({ data }) => {
  const node = data.cms.node

  return (
    <BaseHead>
      <title>{node.title} | DigiD</title>
    </BaseHead>
  )
}

export const Head = WebformHead

// Recursive fragments are not permitted in GraphQL.
// The current query goes 12 levels deep...
export const query = graphql`
  query($vid: String!, $language: CMS_LanguageId!) {
    cms {
      node: nodeRevisionById(id: $vid, language: $language) {
        entityId
        entityBundle
        ... on CMS_NodeWebform {
          title
          body {
            processed
          }
          webform {
            targetId
          }
        }
      }
    }

    topMenu: cms {
      menuByName(name: "top", language: $language) {
        links {
          label
          url {
            path
          }
        }
      }
    }

    footerMenu: cms {
      menuByName(name: "footer", language: $language) {
        links {
          ... Link
          links {
            ... Link
          }
        }
      }
    }

    popularMenu: cms {
      menuByName(name: "popular", language: $language) {
        links {
          ... Link
        }
      }
    }
  }

`

export default webform
