import React, { useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

function TextAreaWidget(props) {
  const width = `${props.options.size}%`
  const NEW_LINE_REGEX = /(\r\n|\n|\r)/g
  let initialValue = ''
  const countLimit = props.options.maxlength
  const [value, setValue] = useState(initialValue.slice(0, countLimit))
  const hasOverflowed = countLimit - value.length <= 0
  const counter = value.length
  const { t } = useTranslation()

  const onChange = (event) => {
    let newValue = event.target.value.replace(NEW_LINE_REGEX, '\n')
    newValue = newValue.slice(0, countLimit)
    event.target.value = newValue
    setValue(newValue)
    props.onChange(event.target.value) // Set value of custom widget in formData
  }

  let describedBy = `${props.id}-character-counter`

  if (props.rawErrors && props.rawErrors.length) {
    describedBy += ` ${props.id}-error`
  }

  return (
    <div className="wrapper" style={{width: width}}>
      <textarea
        id={props.id}
        value={value}
        onChange={onChange}
        placeholder={props.placeholder !== undefined ? props.placeholder : undefined}
        className={cn('form-control', {
          'has-value': props.value !== undefined && props.value !== '',
        })}
        aria-describedby={describedBy}
        required={props.required}
        aria-required={props.required}
        aria-invalid={props.rawErrors && props.rawErrors.length ? 'true' : undefined}
      />
      <div
          className="textarea-counter"
          role="status"
          style={{
            textAlign: 'right',
            color: hasOverflowed ? 'red' : '#767676',
          }}
          id={`${props.id}-character-counter`}
        >
        {counter}/{countLimit} {t('contact_form.characters')}
        </div>
    </div>
  )
}

if (process.env.NODE_ENV !== "production") {
  TextAreaWidget.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    id: PropTypes.string,
  };
}

export default TextAreaWidget
