import React from 'react'
import Attention from '../../assets/icons/attention.svg';

function ErrorList(props) {
  const { errors = [] } = props;

  if (errors.length === 0) {
    return null;
  }

  return (
    <div>
      <ul role="alert" className="error-detail bs-callout bs-callout-info" id={props.id}>
        {errors
          .filter(elem => !!elem)
          .map((error, index) => {
            return (
              <li className="text-danger" key={index}>
                <Attention className="attention" />
                {error}
              </li>
            );
          })}
      </ul>
    </div>
  );
}

function FieldTemplate(props) {
  const {id, classNames, help, required, description, rawErrors, children} = props;
  const label = props.schema.title
  const widget = props.uiSchema['ui:widget']

  if(widget ==='radio') {
    return (
      <fieldset
        className={`${classNames}${props.uiSchema['ui:autocomplete'] === 'new-password' ? ' hname' : ''}`}
        aria-required={props.required}
        aria-invalid={rawErrors && rawErrors.length ? 'true' : undefined}
        aria-describedby={rawErrors && rawErrors.length ? `${id}-error` : undefined}
      >
        {label &&
          <legend htmlFor={id}>{label}{required ? "*" : null}</legend>
        }
        {description}
        {children}
        <ErrorList errors={rawErrors} id={`${id}-error`} />
        {help}
    </fieldset>
    )
  } else {
    return (
      <div className={`${classNames}${props.uiSchema['ui:autocomplete'] === 'new-password' ? ' hname' : ''}`}>
      {label &&
        <label htmlFor={id}>{label}{required ? "*" : null}</label>
      }
      {description}
      {children}
      <ErrorList errors={rawErrors} id={`${id}-error`} />
      {help}
    </div>
    )
  }
}

export default FieldTemplate
